.animated-buttonV1 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    animation: shake 0.1s ease infinite alternate; /* Animation sans hover */
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    100% { transform: translateX(-2px); } /* Décalage de -2px */
  }

  .animated-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0F0F0F;
    color: #fff;
    text-decoration: none;
    border-radius: 500px;
    font-weight: 700;
    animation: rotateAndScale 1s ease infinite alternate; /* Animation sans hover */
  }
  .animated-button:hover {
    background-color: #0F0F0F; /* Changement de couleur de fond au survol */
    color: #fff; /* Couleur de texte blanche au survol */
  
  }
  @keyframes rotateAndScale {
    0% {
      transform: rotate(0) scale(1); /* Pas de rotation, taille normale */
    }
    50% {
      transform:  scale(1.05); /* rotate(2deg) Rotation de 15 degrés, agrandissement à 120% */
    }
    100% {
      transform: rotate(0) scale(1); /* Retour à la rotation de départ, taille normale */
    }
  }

  .badge {
    display: table-row;
    align-items: center;
    justify-content: center;
    width: 50px; /* Ajustez selon vos besoins */
    height: 50px; /* Ajustez selon vos besoins */
    background-color: #fff; /* Couleur de fond */
    color: #0F0F0F; /* Couleur du texte */
    border-radius: 50%; /* Pour obtenir la forme ronde */
    padding: 8px; /* Espace intérieur */
    font-family: Arial, sans-serif; /* Police de caractères */
    text-align: center; /* Alignement centré */
  }

  .badge:active {
    transform: translateY(1px); /* Décalage vers le bas */
    border-color: #ff4d4d;
  }
  
  .like-count {
    display: block;
    font-size: 12px; /* Taille de la police pour le nombre de likes */
  }

  .fa-lg {
    color: #ff4d4d;
    font-size: 18px; /* Taille de l'icône */
  }

  @keyframes heartBeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .animate-heart {
    animation: heartBeat 2s ease infinite; /* Animation en boucle */
  }

  /* Petite taille d'écran */
@media screen and (max-width: 600px) {
  .text-adaptive {
    font-size: 14px;
  }
}

/* Taille moyenne d'écran */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .text-adaptive {
    font-size: 16px;
  }
}

/* Grande taille d'écran */
@media screen and (min-width: 1025px) {
  .text-adaptive {
    font-size: 18px;
  }
}


.bouton-social img {
  vertical-align: middle;
  width: 30px; /* Ajustez la taille de l'image selon vos besoins */
  height: 30px; /* Ajustez la taille de l'image selon vos besoins */
}